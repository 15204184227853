<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Ocorrência Categoria' : 'Cadastrar Ocorrência Categoria'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="formatRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome da Ocorrência Categoria"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome da Ocorrência Categoria"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>   
          <b-col md="3">
              <b-form-group label="Entidade">
                <v-select
                  v-if="OccurrenceEntity.length > 0 && OccurrenceEntitySelected"
                  v-model="OccurrenceEntitySelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OccurrenceEntity"
                  autocomplete="off"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>       
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _occurrenceCategoryService from "@/services/audiovisual/occurrence-category-service";
import _occurrenceEntityService from "@/services/audiovisual/occurrence-entity-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: { permission: `permission.occurrence.category.edit` },
      btcreate: { permission: `permission.occurrence.category.create`, },
      btdelete: { permission: `permission.occurrence.category.delete`, },
      loading: false,
      record: {
        id: 0,
        name: "",
        occurrenceEntity: ""
      },
      OccurrenceEntity: [],
      OccurrenceEntitySelected: { label: "entidades....", value: "-3" },
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getRecord();
    this.getOccurrenceEntity();
  },
  methods: {
    validationForm() {
      this.$refs.formatRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getOccurrenceEntity() {
      this.loading = true;
      _occurrenceEntityService
        .show()
        .then((res) => {
          this.OccurrenceEntity = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _occurrenceCategoryService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;             

            if (this.record.occurrenceEntity) {              
              this.OccurrenceEntitySelected = {
                label: this.record.occurrenceEntity.name,
                value: this.record.occurrenceEntity.id,
              };
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      if (this.OccurrenceEntitySelected) {
        this.record.occurrence_entity_id = this.$utils.getValueSelected(this.OccurrenceEntitySelected);
        this.record.occurrenceEntity = { name: this.OccurrenceEntitySelected.label };
      }
      
      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _occurrenceCategoryService.create(payload)
          : _occurrenceCategoryService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>